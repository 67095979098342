<template>
  <div>
    <b-card title="Configuracion - Roles y Permisos">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="8">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('funcion_permiso-registrar')"
                @click="$refs.modalFormRef.abrir('registrar', [])"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVO</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('funcion_permiso-editar')"
                @click="$refs.modalFormRef.abrir('actualizar', item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span class="align-middle">EDITAR</span>
                </span>
                <feather-icon icon="Edit2Icon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('funcion_permiso-eliminar')"
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ELIMINAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" md="4" class="mt-1 mt-md-0">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="10" xl="6">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemRol="selectItemRol"
        />
      </b-card-text>

      <b-card-text>
        <b-row>
          <b-col cols="6" md="6">
            <!-- <span class="text-nowrap text-bold">
              <strong>
                Mostrando {{ from }} - {{ to }} de {{ total }} Registros
              </strong>
            </span> -->
          </b-col>
          <b-col cols="6" md="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
              align="right"
              size="sm"
              class="my-0"
            >
              <template #prev-text>
                <feather-icon size="18" icon="ChevronLeftIcon" />
              </template>
              <template #next-text>
                <feather-icon size="18" icon="ChevronRightIcon" />
              </template>
              <template #last-text>
                <feather-icon size="18" icon="ChevronsRightIcon" />
              </template>
              <template #first-text>
                <feather-icon size="18" icon="ChevronsLeftIcon" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <ModalFormRol
      ref="modalFormRef"
      :item="item"
      @actualizarRol="actualizarRol"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import ModalFormRol from "../components/ModalFormRol.vue";
import RolServices from "../services/index";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    BPagination,
    ModalFormRol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
    };
  },
  created() {},
  methods: {
    // SERVICES
    async listar() {
      this.loading = true;
      try {
        const response = await RolServices.get({});
        this.items = response.data;
        this.item = [];
        this.buttonDisabled = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    async eliminar(id) {
      try {
        const response = await RolServices.destroy(id);
        this.$bvToast.toast("Se elimino correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    // Emit modal rol
    actualizarRol() {
      this.listar();
      this.item = [];
      this.buttonDisabled = true;
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItemRol(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    confirmarEliminar(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea eliminar : " + item.nombre, {
          title: "ELIMINAR FUNCION ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si, Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.eliminar(item.id);
          }
        });
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
