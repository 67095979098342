<template>
  <b-modal
    id="modal-from-rol"
    v-model="modalRol"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    scrollable
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    size="lg"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="lg"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label="* NOMBRE"
              label-for="nit"
            >
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  v-model="form.nombre"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6">
            <div
              v-for="(permisos, grupo, index) in permisosGrupos[0]"
              :key="grupo"
            >
              <div>
                <hr v-if="index != 0" />
                <h6 class="text-uppercase">
                  {{ grupo }}
                </h6>
              </div>

              <b-form-group
                class="mb-0-5"
                v-for="permiso in permisos"
                :key="permiso.id"
              >
                <b-form-checkbox v-model="form.permisos" :value="permiso">
                  {{ permiso.nombre }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>

          <b-col cols="6">
            <div
              v-for="(permisos, grupo, index) in permisosGrupos[1]"
              :key="grupo"
            >
              <div>
                <hr v-if="index != 0" />
                <h6 class="text-uppercase">
                  {{ grupo }}
                </h6>
              </div>

              <b-form-group
                class="mb-0-5"
                v-for="permiso in permisos"
                :key="permiso.id"
              >
                <b-form-checkbox v-model="form.permisos" :value="permiso">
                  {{ permiso.nombre }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import store from "@/store/index";
import RolServices from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    vSelect,
    BFormCheckbox,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        nombre: "",
        permisos: [],
      },
      permisos: [],
      required,
      submitted: false,
      errores: [],
      modalRol: false,
      grupos: [],
      gruposDividido2: [],
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR FUNCION" : "NUEVA FUNCION";
    },
    permisosGrupos() {
      const permisosGrupo1 = {};
      this.gruposDividido2[0]?.forEach((grupo) => {
        permisosGrupo1[grupo] = this.permisos.filter((p) => p.grupo == grupo);
      });

      const permisosGrupo2 = {};
      this.gruposDividido2[1]?.forEach((grupo) => {
        permisosGrupo2[grupo] = this.permisos.filter((p) => p.grupo == grupo);
      });

      return [permisosGrupo1, permisosGrupo2];
    },
  },
  watch: {
    "form.nombre": function () {
      this.errores.nombre = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      this.obtenerPermisosYGrupos();
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.nombre = "";
          this.form.permisos = [];
          this.modalRol = true;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.nombre = data["nombre"];
          this.form.permisos = data["permisos"];
          this.modalRol = true;
          break;
        }
      }
      this.errores = [];
    },
    cerrarModal() {
      this.form.id = null;
      this.modalRol = false;
    },
    actualizarRol(item) {
      this.form.id = null;
      this.modalRol = false;
      this.$emit("actualizarRol", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      try {
        const response = await RolServices.store(this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarRol(response.data.rol);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await RolServices.update(this.form.id, this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarRol(response.data.rol);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        if (err.response.data.errors) {
          this.errores = err.response.data.errors;
        }
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    async obtenerPermisosYGrupos() {
      try {
        const [responsePermisos, responseGrupos] = await Promise.all([
          RolServices.getPermisos(),
          RolServices.getGrupos(),
        ]);
        this.permisos = responsePermisos.data;
        this.grupos = responseGrupos.data;
        this.dividirGrupos();
        this.errores = [];
      } catch (err) {
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    dividirGrupos() {
      const grupo1 = [];
      const grupo2 = [];
      this.grupos.forEach((element, index) => {
        if (index < parseInt(this.grupos.length / 2)) {
          grupo1.push(element);
        } else {
          grupo2.push(element);
        }
      });

      this.gruposDividido2 = [grupo1, grupo2];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
</style>