<template>
  <b-overlay :show="loading">
    <ag-grid-vue
      :gridOptions="gridOptions"
      class="ag-theme-balham ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      @grid-ready="onGridReady"
      :rowData="items"
      rowSelection="single"
      colResizeDefault="shift"
      :animateRows="false"
      :floatingFilter="true"
      :pagination="false"
      :rowClassRules="rowClassRules"
      @selection-changed="onSelectionChanged"
      @first-data-rendered="onFirstDataRendered"
      :alwaysShowVerticalScroll="true"
      :suppressPaginationPanel="true"
    >
    </ag-grid-vue>
  </b-overlay>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BCard, BCardText, BOverlay } from "bootstrap-vue";
import { truncateSync } from "fs";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
  },
  props: ["items", "loading"],
  data() {
    return {
      // grid
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        floatingFilter: true,
        //editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "ROL (FUNCION)",
          field: "nombre",
          filter: truncateSync,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 300,
          maxWidth: 300,
          filter: false,
        },
        {
          headerName: "GUARD",
          field: "nombre_guard",
          minWidth: 300,
          maxWidth: 300,
          filter: false,
        },
        {
          headerName: "PERMISOS",
          valueGetter(params) {
            return params.data.permisos.map((permiso) => permiso.nombre);
          },
          minWidth: 500,
        },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
      searchQuery: "",
    };
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemRol", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemRol", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
